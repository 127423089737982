import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	accordion: {
		boxShadow: "none",
		"&::before": {
			display: "none"
		},
		"& + .MuiAccordion-root": {
			marginTop: 1
		}
	},
	accordionSummary: {
		background: theme.palette?.background?.light
	},
	accordionDetails: {
		padding: theme.spacing(2, 1, 0)
	},
	number: {
		fontWeight: theme.typography?.fontWeightBold,
		lineHeight: 1
	},
	date: {
		lineHeight: 1,
		marginTop: theme.spacing(0.25)
	},
	link: {
		"& a": {
			color: theme.palette?.primary?.main,
			fontWeight: theme.typography?.fontWeightBold,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline"
			}
		}
	}
}));

export default styles;
