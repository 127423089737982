import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext, PageContext, UserContext, changeLanguage } from "@Core";
import DetectLayout from "./detectLayout";

const DetectPage = () => {
	const { REACT_APP_PATH } = process.env;
	const [appData, setAppData] = useContext(AppContext);
	const [pageData, setPageData] = useContext(PageContext);
	const [userData, setUserData] = useContext(UserContext);
	const { i18n } = useTranslation();

	const { pathname } = window.location;

	useEffect(() => {
		fetchUser();

		getPage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname]);

	const fetchUser = async () => {
		if (userData) {
			return null;
		}

		const fetchURL = REACT_APP_PATH + "/api/user/data";

		// read and send token

		const headers = {};

		if (localStorage.getItem("logintoken")) {
			headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
		}

		const response = await fetch(fetchURL, {
			headers
		});
		const data = await response.json();

		const authorization = data?.state !== 1 && (await fetchAuthorization());

		const fetchedUserData = {
			...data,
			...authorization
		};

		setUserData(fetchedUserData);
	};

	const fetchAuthorization = async () => {
		const { REACT_APP_PATH } = process.env;

		const fetchURL =
			REACT_APP_PATH + "/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.GuestApi/GetInfo.api";

		// read and send token

		const headers = {};

		if (localStorage.getItem("logintoken")) {
			headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
		}

		const response = await fetch(fetchURL, {
			headers
		});

		const data = await response.json();

		return data;
	};

	const getAppData = async lang => {
		if (appData) {
			return;
		}

		const data = await fetchAppData(lang);

		if (!data) return;

		setAppData(prevData => ({ ...prevData, ...data }));
	};

	const getPage = async () => {
		if (pageData?.currentFetchedURL === pathname) {
			return;
		}

		const data = await fetchData();

		setPageData({
			currentFetchedURL: pathname,
			data
		});

		getAppData(data?.lang);

		if (data?.lang !== i18n.language) {
			changeLanguage(i18n, data?.lang);
			return;
		}
	};

	if (!pageData?.data) {
		return <></>;
	}

	return (
		<>
			<DetectLayout></DetectLayout>
			<micado-ial
				language={pageData?.data?.lang}
				path={REACT_APP_PATH}
				page-id={pageData?.data?.id}
				show-on-consent-accepted={false}
			></micado-ial>
		</>
	);
};

const fetchData = () => {
	return new Promise((resolve, reject) => {
		const absoluteURL = window.location.href;
		const { REACT_APP_PATH } = process.env;

		const fetchURL = `${REACT_APP_PATH}/Micado.Web.JSONEngine/Micado.Web.JSONEngine.IO.Api.PageApi/GetJSONData.api?absoluteUrl=${absoluteURL}`;

		// read and send token

		const headers = {};

		if (localStorage.getItem("logintoken")) {
			headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
		}

		fetch(fetchURL, {
			headers
		})
			.then(response => {
				if (response.status === 401) {
					return { _statusCode: 401 };
				}

				if (response.status === 404) {
					return { _statusCode: 404 };
				}

				return response.json();
			})
			.then(data => {
				const { state, url } = data || {};

				if (state === 301) {
					window.location.href = url;
					return;
				}

				resolve(data);
			});
	});
};

const fetchAppData = lang => {
	return new Promise((resolve, reject) => {
		const { REACT_APP_PATH } = process.env;

		const fetchURL = REACT_APP_PATH + "/api/website/profile/data?lang=" + lang;

		// read and send token

		const headers = {};

		if (localStorage.getItem("logintoken")) {
			headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
		}

		fetch(fetchURL, {
			headers
		})
			.then(response => {
				return response.json();
			})
			.then(data => {
				resolve(data);
			});
	});
};

export default DetectPage;
