import React, { useEffect, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Alert from "@material-ui/lab/Alert";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import localDE from "./locals/de.json";
import localEN from "./locals/en.json";

import styles from "./ReservationsList.styles";

const ReservationsList = ({ lang }) => {
	const { REACT_APP_PATH } = process.env;
	const [items, setItems] = useState([]);
	const [links, setLinks] = useState({});
	const [loading, setLoading] = useState(true);
	const css = styles();
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	useEffect(() => {
		const fetchReservations = async () => {
			try {
				const response = await fetch(
					`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.GuestApi/ListReservations.api`,
					{
						headers: getHeaders()
					}
				);
				const { items = [] } = await response.json();

				setItems(items);
			} catch (error) {
			} finally {
				setLoading(false);
			}
		};

		fetchReservations();
	}, [REACT_APP_PATH]);

	const fetchLinks = async guid => {
		if (links?.[guid]?.links || links?.[guid]?.loading) return;

		setLinks(prev => ({ ...prev, [guid]: { links: [], loading: true } }));

		const headers = {};

		if (localStorage.getItem("logintoken")) {
			headers["Authorization"] = "Bearer " + localStorage.getItem("logintoken");
		}

		try {
			const response = await fetch(
				`${REACT_APP_PATH}/Micado.Tourism.Web/Micado.Tourism.Web.IO.Api.GuestApi/ListReservationLinks.api?reservationGUID=${guid}`,
				{
					headers
				}
			);
			const { items = [] } = await response.json();

			setLinks(prev => ({ ...prev, [guid]: { links: items, loading: false } }));
		} catch (error) {
			setLinks(prev => ({ ...prev, [guid]: { links: [], loading: false } }));
		}
	};

	if (items.length === 0 && !loading) {
		return <Alert severity="error">{l("reservationsList.noReservations")}</Alert>;
	}

	return (
		<div className={css.reservationsList}>
			{items.map((item, index) => {
				const { date, guid, number } = item;
				const { links: urls = [], loading } = links?.[guid] || {};

				const formattedDate = formatDate(date, lang);

				return (
					<Accordion
						className={css.accordion}
						key={index}
						onChange={(_, expanded) => expanded && fetchLinks(guid)}
					>
						<AccordionSummary className={css.accordionSummary} expandIcon={<ExpandMoreIcon />}>
							<div>
								{number && (
									<Typography
										className={css.number}
										dangerouslySetInnerHTML={{ __html: number }}
									/>
								)}
								{formattedDate && (
									<Typography
										className={css.date}
										dangerouslySetInnerHTML={{ __html: formattedDate }}
										variant="body2"
									/>
								)}
							</div>
						</AccordionSummary>
						<AccordionDetails className={css.accordionDetails}>
							{loading ? (
								<Typography>{l("reservationsList.loading")}</Typography>
							) : urls.length > 0 ? (
								<div>
									{urls.map((link, index) => {
										const { type, url } = link;
										return (
											<div className={css.link} key={index}>
												<a href={url} rel="noopener noreferrer" target="_blank">
													{type === "invoice" && l("reservationsList.invoice")}
													{type === "precheckin" && l("reservationsList.preCheckIn")}
												</a>
											</div>
										);
									})}
								</div>
							) : (
								<Typography>{l("reservationsList.noLinks")}</Typography>
							)}
						</AccordionDetails>
					</Accordion>
				);
			})}
		</div>
	);
};

const formatDate = (date, lang) => {
	if (!date) return;

	const dateObj = new Date(date);

	return dateObj.toLocaleString(lang, {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "2-digit",
		minute: "2-digit",
		hour12: false
	});
};

const getHeaders = () => {
	const headers = {};

	const token = localStorage.getItem("logintoken");

	if (token) {
		headers["Authorization"] = `Bearer ${token}`;
	}

	return headers;
};

export default ReservationsList;
